import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 14 15"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M9 7.667c.185 0 .364-.024.534-.071a.5.5 0 0 1 .266.965c-.257.07-.526.106-.8.106-1.285 0-2.335-.806-2.472-1.834h-.527a.333.333 0 0 1 0-.666h.5V5.5H6a.333.333 0 0 1 0-.667h.527C6.665 3.805 7.715 3 9.001 3c.273 0 .542.036.799.107a.5.5 0 0 1-.266.964A2.02 2.02 0 0 0 9 4c-.705 0-1.313.364-1.463.833h1.796a.333.333 0 0 1 0 .667H7.5v.667h1.833a.333.333 0 0 1 0 .666H7.537c.149.47.758.834 1.463.834Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M8.667 10.833c-2.758 0-5-2.242-5-5 0-2.757 2.242-5 5-5 2.757 0 5 2.243 5 5 0 2.758-2.243 5-5 5Zm0-8.666A3.671 3.671 0 0 0 5 5.833 3.671 3.671 0 0 0 8.667 9.5a3.671 3.671 0 0 0 3.666-3.667 3.671 3.671 0 0 0-3.666-3.666Z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M8.667 11.833a5.91 5.91 0 0 1-.765-.054 3.653 3.653 0 0 1-2.569 1.054 3.671 3.671 0 0 1-3.666-3.666c0-1 .403-1.906 1.054-2.569a6.036 6.036 0 0 1-.054-.765c0-.32.032-.631.08-.938A4.997 4.997 0 0 0 .333 9.167c0 2.757 2.243 5 5 5 1.81 0 3.394-.97 4.272-2.414a5.97 5.97 0 0 1-.938.08Z"
    }, null, -1)
  ])))
}
export default { render: render }